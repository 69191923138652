<script>
import axios from 'axios'
import router from '../../router/index'
import {
    mapGetters
} from 'vuex'

export default {
    computed: {
        ...mapGetters({
            userInfo: 'GET_USER_INFO',
            loginStatus: 'GET_LOGIN_STATUS'
        })
    },
    async mounted () {
        if(typeof (this.$route.params.sub) === 'undefined'){
            alert('Link error')
            router.replace('/')
        }
        else if(this.loginStatus === false){
            alert('Please login before')
            router.replace('/')
        }
        else{
            try {
                const formData = new FormData();
                formData.append('auth_reference', this.userInfo.auth_token);
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('sub', this.$route.params.sub);
                await axios
                    .post("https://api2.novelrealm.com/module/apple&submodule=link2", formData)
                    .then(res => {
                        //console.log(res)
                        if (res.data.status === 200) {
                            alert('Link success')
                        }
                        else if (res.data.status === 403) {
                            if(res.data.description === "Already_LINK"){
                                alert('This appleid already link to account')
                            }
                            else{
                                alert(res.data.description)
                            }
                        }
                        else{
                            alert('Link error')
                        }
                    })
                    router.replace('/profile')
            }
            catch (e)
            {
                console.log(e)
                alert('Link error by api')
                router.replace('/')
            }
        }
    }
}
</script>